<template>
  <div class="post-container">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Информация автора
        </h4>
      </div>
      <div
        v-if="post"
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Имя"
              >
                <b-form-input
                  v-model="post.createdBy.name"
                  readonly
                  placeholder="Имя"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Фамилия"
              >
                <b-form-input
                  v-model="post.createdBy.surname"
                  readonly
                  placeholder="Фамилия"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Номер телефона"
              >
                <b-form-input
                  v-model="post.createdBy.number"
                  readonly
                  type="tel"
                  placeholder="Номер телефона"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Название"
              >
                <b-form-input
                  v-model="post.title"
                  placeholder="Название"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Дата создания"
              >
                <b-form-input
                  v-model="date"
                  readonly
                  placeholder="Дата создания"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Статус"
              >
                <v-select
                  v-model="post.status"
                  :close-on-select="true"
                  :options="statuses"
                  label="Статус"
                  class="select"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Количество лайков"
              >
                <b-form-input
                  v-model="post.likesCount"
                  readonly
                  placeholder="Количество лайков"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Теги
        </h4>
      </div>
      <b-col md="12">
        <b-form-group>
          <v-select
            v-model="post.tags"
            multiple
            taggable
            :close-on-select="true"
            :options="hashtags"
            label="name"
            class="select"
          />
        </b-form-group>
      </b-col>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Фото
        </h4>
      </div>
      <div class="card-body">
        <div
          v-if="post.file && post.file.fullPath"
          class="image-container"
        >
          <x-icon
            size="1x"
            class="delete-icon"
            @click="deletePostImage"
          />
          <img
            :src="post.file.fullPath"
            alt="Flexible Image"
          >
        </div>
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <div class="upload-wrap">
                <file-uploader
                  hide-control-buttons
                  @updatePhotos="addImage"
                />
              </div>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <h4 class="card-title">
          Описание
        </h4>
        <quill-editor
          v-model="post.description"
        />
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="updatePost"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
} from 'bootstrap-vue'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { XIcon } from 'vue-feather-icons'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FileUploader from '@/global-components/FileUploader.vue'
import crudModule from '../../libs/crud_module'

const postModule = crudModule({
  entity_name: 'post',
})

const tagsModule = crudModule({
  entity_name: 'hash-tag/all',
})

export default {
  name: 'Post',
  components: {
    FileUploader,
    vSelect,
    quillEditor,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    XIcon,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      isloading: false,
      post: {},
      statuses: ['Опубликован', 'Черновик'],
      hashtags: [],
      date: null,
    }
  },
  async created() {
    try {
      await postModule.fetchItem(this.$route.params.id).then(result => {
        this.post = result.data
        this.date = this.$moment(result.data.createdAt).format('DD.MM.YYYY')
      })
      await tagsModule.fetchItems().then(result => {
        this.hashtags = result.data
      })
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    deletePostImage() {
      this.post.file = null
    },
    addImage(image) {
      this.post.file = image
      this.post.fileId = image.id
    },
    async updatePost() {
      try {
        await postModule.updateItemPatch(this.post.id, this.post)
          .then(() => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Пост успешно узменен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'posts' })
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
    .image-container {
        position: relative;
        display: inline-block;
        max-width: 100%;
        max-height: 200px;
        overflow: hidden;
        border: 1px solid #d8d6de;
        border-radius: 8px;

        img {
            margin-top: 15px;
            max-width: 100%;
            height: auto;
            max-height: 200px;
        }

        .delete-icon {
            position: absolute;
            top: 1px;
            right: 1px;
            cursor: pointer;
        }
    }
    .save-button{
      position: fixed!important;
      bottom: 30px;
      right: 15px;
      z-index: 1000;
    }
    .select {
      padding: 21px;
      padding-top: 0px;
      width: 100%;
    }
</style>
<style lang="scss">
    .post-container {
        .select {
            padding: 0;
        }

        .vs__open-indicator {
            cursor: pointer;
            fill: #d8d6de;
        }
    }
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
